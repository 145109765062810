import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Chart from 'react-apexcharts';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import capitalize from 'lodash/capitalize';
import moment from 'moment-timezone';

import { durationFormat, getHumanizeDurationShort } from '@parkly/shared/helpers';

import { useAnalytic } from 'api/query/analytics';
import { ANALYTICS_MODES } from 'config/constants';
import { useDebounce } from 'helpers/hooks';

import { useStyles } from './styles';

export default function AnalyticChart({
  mode,
  type,
  facilityIds,
  start,
  end,
  tariffPlanIds,
  gateIds,
  accessStatuses,
  sessionDurationFrom,
  sessionDurationTo,
}) {
  const styles = useStyles();
  const [title, setTitle] = useState('');
  const [series, setSeries] = useState([]);
  const [actualMode, setActualMode] = useState('');

  const params = useMemo(() => ({
    mode,
    type,
    facilityIds,
    tariffPlanIds,
    gateIds,
    accessStatuses,
    sessionDurationFrom,
    sessionDurationTo,
    start: start && end ? start : undefined,
    end: start && end ? end : undefined,
  }), [mode, type, facilityIds, tariffPlanIds, gateIds, accessStatuses, sessionDurationFrom, sessionDurationTo, start, end]);

  const variables = useDebounce(params, 0);

  const { data: chartData, isFetching, isLoading } = useAnalytic({
    variables,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (chartData) {
      const { title: chartTitle, data, mode: aMode } = chartData;
      setTitle(chartTitle);
      setActualMode(aMode);

      setSeries((data || []).map(({ name, values }) => ({
        name,
        data: (values || []).map(({ date, value }) => ({
          x: date,
          y: value,
        })),
      })));
    }
  }, [chartData]);

  const formatXAxis = useCallback((value) => {
    const date = moment(value);

    if (actualMode === ANALYTICS_MODES.hour) {
      return date.format('HH:mm');
    }

    if (actualMode === ANALYTICS_MODES.day) {
      return date.format('DD MMM');
    }

    if (actualMode === ANALYTICS_MODES.week) {
      return date.format('DD MMM');
    }

    if (actualMode === ANALYTICS_MODES.month) {
      return date.format('MMM');
    }

    return '';
  }, [actualMode]);

  const formatXTooltip = useCallback((value) => {
    const date = moment(value);

    if (actualMode === ANALYTICS_MODES.hour) {
      return `${date.format('HH:mm')} - ${date.add(1, 'hour').format('HH:mm')}`;
    }

    if (actualMode === ANALYTICS_MODES.day) {
      return `${date.format('DD MMM')} - ${date.add(1, 'day').format('DD MMM')}`;
    }

    if (actualMode === ANALYTICS_MODES.week) {
      return `${date.format('DD MMM')} - ${date.add(1, 'week').format('DD MMM')}`;
    }

    if (actualMode === ANALYTICS_MODES.month) {
      return capitalize(date.format('MMMM'));
    }

    return '';
  }, [actualMode]);

  const formatYAxis = useCallback((value) => {
    if (type === 'avg_duration_finish_session_by_status') {
      return durationFormat(value * 60 * 1000, 'D hh:mm');
    }

    return value;
  }, [type]);

  const formatYTooltip = useCallback((value) => {
    if (type === 'avg_duration_finish_session_by_status') {
      return getHumanizeDurationShort(value * 60 * 1000, ['d', 'h', 'm']);
    }

    if (type.indexOf('count') !== -1) {
      return `${value} шт.`;
    }

    if (type.indexOf('sum') !== -1) {
      return `${value} ₽`;
    }

    return value;
  }, [type]);

  const yTitle = useMemo(() => {
    if (type.indexOf('count') !== -1) {
      return 'шт.';
    }

    if (type.indexOf('sum') !== -1) {
      return '₽';
    }

    return undefined;
  }, [type]);

  const options = useMemo(() => ({
    colors: ['#2E93fA', '#66DA26', '#775dd0', '#E91E63', '#FF9800', '#ff4560', '#004BF6', '#00D88A', '#f9b98f'],
    noData: {
      text: 'Пока пусто',
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      id: `${type}_${actualMode}`,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      fontFamily: 'Lato,Roboto',
      animations: {
        enabled: true,
        animateGradually: {
          enabled: false,
        },
      },
    },
    tooltip: {
      followCursor: true,
      x: {
        formatter: formatXTooltip,
      },
      y: {
        formatter: formatYTooltip,
      },
      marker: {
        show: true,
      },
    },
    yaxis: {
      showForNullSeries: false,
      decimalsInFloat: 1,
      title: {
        text: yTitle,
      },
      labels: {
        formatter: formatYAxis,
      },
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
      labels: {
        formatter: formatXAxis,
        maxHeight: 60,
      },
    },
  }), [type, actualMode, formatXTooltip, formatXAxis, formatYTooltip, formatYAxis, yTitle]);

  if (isFetching) {
    return (
      <Paper className={styles.paper}>
        <Typography className={styles.title}>
          {title}
        </Typography>
        <Grid container style={{ height: 315 }} justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={styles.paper}>
      <Typography className={styles.title}>
        {title}
      </Typography>
      <Chart
        height={300}
        type="bar"
        options={options}
        series={series}
      />
    </Paper>
  );
}
