import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { StringParam, withDefault } from 'use-query-params';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import SortedBigFirstIcon from '@parkly/shared/components/atoms/icons/SortedBigFirstIcon';
import SortedSmallFirstIcon from '@parkly/shared/components/atoms/icons/SortedSmallFirstIcon';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import { formatPhoneNumber } from '@parkly/shared/helpers';

import { useCustomers } from 'api/query/customers';
import { useActiveTariffPlans } from 'api/query/tariffs';
import FiltersContainer from 'components/templates/containers/FiltersContainer';
import HeaderWithNavigationLinks from 'components/templates/HeaderWithNavigationLinks';
import { MODULES_KEYS, PATH_PAGES, RIGHT_KEYS } from 'config/constants';
import {
  useCheckFacilityRights, useCommonRights, useDebounce, useHasModule,
} from 'helpers/hooks';
import { PageParam, withQueryParams } from 'helpers/queryParams';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

function getInitParams() {
  return {
    searchValue: '',
    sorting: {},
    defaultTariffPlan: 'unselect',
    guest: 'unselect',
    page: 1,
  };
}

const GUEST_ITEMS = [
  { id: 0, title: 'Создан оператором' },
  { id: 1, title: 'Создан системой' },
];

function getNextSortStatus(status) {
  if (!status) {
    return 'sort';
  }

  if (status === 'sort') {
    return 'sortReverse';
  }

  return null;
}

function getHeaders({ t }) {
  const headers = [
    {
      id: 'name',
      label: t('customers.customer'),
    },
    {
      id: 'phone',
      label: t('customers.phone'),
    },
    {
      id: 'tokens',
      label: t('customers.tokens'),
    },
    {
      isSorted: true,
      id: 'lastActivity',
      label: t('customers.lastActivity'),
    },
  ];

  return headers.filter((item) => !!item);
}

const getTokenNodeByType = ({
  type, token, isSingle, isActive,
}, styles, key) => {
  let tokenStr = '';
  if (type === 0) {
    tokenStr = formatPhoneNumber(token).replace(/\s/g, ' ');
  }
  if (type === 1) {
    tokenStr = token;
  }
  if (type === 2) {
    tokenStr = 'RFID';
  }

  let color = 'red';
  if (isActive) {
    color = '#43D37D';
  }
  if (isSingle) {
    color = 'blue';
  }

  return (
    <Typography key={`${key}-${tokenStr}-${color}`} className={styles.tokenStr}>
      <CircleIcon color={color} />
      {tokenStr}
    </Typography>
  );
};

function prepareParam({ params, facilityId }) {
  const {
    search: searchValue, defaultTariffPlan, guest, sorting = {}, page,
  } = params || {};

  const sortRules = Object.keys(sorting).filter((currentId) => !!sorting[currentId]);
  const sortParam = sortRules.length > 0
    ? {
      sortBy: sortRules.join(','),
      sortDirection: sortRules
        .map((id) => {
          const rule = sorting[id];
          if (rule === 'sortReverse') {
            return 'desc';
          }

          return 'asc';
        })
        .join(','),
    }
    : {};

  const search = (searchValue || '').trim();

  const reqParam = {
    search,
    page,
    defaultTariffPlan,
    guest,
    facilityId,
    sorting,
    ...sortParam,
  };

  return reqParam;
}

function AllCustomersPage({
  match,

  // from HOC
  params,
  setParams,
}) {
  const styles = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  useCurrentFacilityMatch();
  const currentFacilityId = ((match || {}).params || {}).facilityId;
  const variables = useDebounce(params, 300);

  const { data: customers, isLoading } = useCustomers({
    variables: {
      ...variables,
      facilityId: currentFacilityId,
    },
    refetchInterval: 1000 * 60 * 10, // 10 mins
    refetchIntervalInBackground: true,
  });

  const { data: activeTariffPlansData } = useActiveTariffPlans({
    variables: { facilityId: currentFacilityId, withTechnical: true },
  });

  const hasCustomerUpdateRight = useCheckFacilityRights(RIGHT_KEYS.customerUpdate, currentFacilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, currentFacilityId);
  const hasTicketShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, currentFacilityId);
  const hasBlackListShowRight = useCommonRights(RIGHT_KEYS.blackListShow);

  const hasPaymentModule = useHasModule(currentFacilityId, MODULES_KEYS.payments);

  const headers = getHeaders({ t });

  const { customersData, customersMeta } = customers || {};

  const { currentPage = 1, lastPage = 1 } = customersMeta || {};

  const {
    search, sorting, defaultTariffPlan, guest,
  } = params;

  const handleParamChange = useCallback(
    (paramName, newValue) => {
      if (params[paramName] === newValue) {
        return;
      }

      setParams((prevState) => ({
        ...prevState,
        page: 1,
        [paramName]: newValue,
      }));
    },
    [params, setParams],
  );

  function sortingSettingsChange({ headerNameId }) {
    const nextSortStatus = getNextSortStatus(sorting[headerNameId]);
    if (nextSortStatus === null) {
      handleParamChange('sorting', {});
      return;
    }

    handleParamChange('sorting', {
      [headerNameId]: getNextSortStatus(sorting[headerNameId]),
    });
  }
  const handleChanges = useCallback(
    (event) => {
      const propName = event.target.name;
      const { value } = event.target;
      handleParamChange(propName, value);
    },
    [handleParamChange],
  );

  function createHeaderClickHandler(id, isSorted) {
    return function headerClickHandler() {
      if (!id || !isSorted) {
        return;
      }
      sortingSettingsChange({
        headerNameId: id,
      });
    };
  }
  const onPageChange = useCallback(
    (_, pageNumber) => {
      handleParamChange('page', pageNumber);
    },
    [handleParamChange],
  );

  const activeTariffPlansItems = useMemo(() => {
    const result = (activeTariffPlansData || []).map(({ id, name }) => ({
      id,
      title: name,
    }));

    result.push({
      id: 0,
      title: 'Не задан',
    });

    return result;
  }, [activeTariffPlansData]);

  return (
    <Container maxWidth="xl" className={styles.container}>
      <HeaderWithNavigationLinks
        links={[
          { to: PATH_PAGES.customers.replace(':facilityId', currentFacilityId), label: 'Клиенты' },
          hasTicketShowRight
            ? { to: PATH_PAGES.tickets.replace(':facilityId', currentFacilityId), label: 'Пропуска' }
            : null,
          hasPassShowRight && hasPaymentModule
            ? { to: PATH_PAGES.passes.replace(':facilityId', currentFacilityId), label: 'Абонементы' }
            : null,
          hasBlackListShowRight ? { to: PATH_PAGES.blackList, label: 'Черный список' } : null,
        ]}
      />
      <div className={styles.pageBody}>
        <Grid container spacing={2}>
          <FiltersContainer>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <CustomTextInput
                    style={{ margin: 0 }}
                    label={t('customers.search')}
                    name="search"
                    onChange={handleChanges}
                    value={search}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ItemSelectors
                    classNameForm={styles.statusSelectForm}
                    className={styles.statusSelect}
                    name="defaultTariffPlan"
                    currentValue={defaultTariffPlan}
                    items={activeTariffPlansItems}
                    defaultItem="Тарифный план не выбран"
                    onChange={handleChanges}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ItemSelectors
                    classNameForm={styles.statusSelectForm}
                    className={styles.statusSelect}
                    name="guest"
                    currentValue={guest}
                    items={GUEST_ITEMS}
                    defaultItem="Кем создан не выбрано"
                    onChange={handleChanges}
                  />
                </Grid>
                {hasCustomerUpdateRight && (
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <CustomBtn
                          className={styles.btn}
                          btnType="primaryGreen"
                          onClick={() => history.push(PATH_PAGES.addCustomer.replace(':facilityId', currentFacilityId))}
                        >
                          {t('customers.addCustomer')}
                        </CustomBtn>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </FiltersContainer>
          <Grid item xs={12}>
            {isEmpty(customersData) && !isLoading && (
              <Grid item className={styles.noDataContainer} component={Paper}>
                <Typography className={styles.noDataStr}>Пока нет клиентов</Typography>
              </Grid>
            )}
            {isLoading && <CircularIndeterminate style={{ minHeight: 600 }} />}
            {!isLoading && !isEmpty(customersData) && (
              <TableContainer className={styles.container} component={Paper}>
                <Table size="small" stickyHeader>
                  <TableHead>
                    <TableRow>
                      {headers.map(({ label, id, isSorted }) => {
                        const sortingRule = sorting[id || ''] || {};
                        const isSort = sortingRule === 'sort';
                        const isSortReverse = sortingRule === 'sortReverse';

                        return (
                          <TableCell
                            key={id}
                            align="left"
                            className={isSorted ? styles.clickable : ''}
                            onClick={createHeaderClickHandler(id, isSorted)}
                          >
                            <div className={styles.headerContainer}>
                              <Typography className={styles.headerStr}>{label}</Typography>
                              {isSort && <SortedSmallFirstIcon className={styles.sortIcon} />}
                              {isSortReverse && <SortedBigFirstIcon className={styles.sortIcon} />}
                            </div>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(customersData || []).map((row, index, array) => {
                      const {
                        id, lastActivityAt, isLastActivityFail, lastActivityFailMessage, phone, name, tokens,
                      } = row || {};
                      const key = id;

                      const phoneStr = phone ? formatPhoneNumber(phone).replace(/\s/g, ' ') : '-';

                      const isLast = index === array.length - 1;

                      const lastActivityTimeStr = lastActivityAt ? moment(lastActivityAt).format('HH:mm:ss') : '-';
                      const lastActivityDateStr = lastActivityAt ? moment(lastActivityAt).format('DD.MM.yyyy') : '-';

                      const tableRowClass = [styles.tableRow, isLast ? styles.tableRowLast : ''];

                      return (
                        <TableRow key={key} className={tableRowClass.join(' ')}>
                          <TableCell align="left">
                            <CustomLink
                              className={styles.nameStr}
                              href={PATH_PAGES.oneCustomerInfo
                                .replace(':facilityId', currentFacilityId)
                                .replace(':id', id)}
                              onClick={(e) => {
                                e.preventDefault();
                                history.push(
                                  PATH_PAGES.oneCustomerInfo
                                    .replace(':facilityId', currentFacilityId)
                                    .replace(':id', id),
                                );
                              }}
                            >
                              {name}
                            </CustomLink>
                          </TableCell>
                          <TableCell align="left">
                            <Typography className={styles.phoneStr}>{phoneStr}</Typography>
                          </TableCell>
                          <TableCell align="left">
                            {isEmpty(tokens) && <Typography> - </Typography>}
                            {(tokens || []).map((token, tokenIndex) => getTokenNodeByType(token, styles, `${key}-${tokenIndex}`))}
                          </TableCell>
                          <TableCell align="left">
                            <Tooltip title={lastActivityFailMessage || ''}>
                              <Typography
                                className={[styles.timeStr, isLastActivityFail ? styles.lastActivityFail : ''].join(
                                  ' ',
                                )}
                              >
                                {lastActivityTimeStr}
                              </Typography>
                            </Tooltip>
                            <Typography
                              className={[styles.dateStr, isLastActivityFail ? styles.lastActivityFail : ''].join(' ')}
                            >
                              {lastActivityDateStr}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {!isLoading && lastPage > 1 && (
              <div className={styles.paginationContainer}>
                <Pagination
                  className={styles.pagination}
                  name="page"
                  page={currentPage}
                  onChange={onPageChange}
                  count={lastPage}
                  color="primary"
                />
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

const AllCustomersPageWithQueryParams = withQueryParams({
  queryParams: {
    search: withDefault(StringParam, ''),
    defaultTariffPlan: withDefault(StringParam, 'unselect'),
    guest: withDefault(StringParam, 'unselect'),
    page: PageParam,
  },
  prepareParam,
})(AllCustomersPage);

export default AllCustomersPageWithQueryParams;
